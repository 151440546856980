document.addEventListener("DOMContentLoaded", function () {
  // get the app button by id
  const appButton = document.getElementById("appButton");
  // register button click event listener
  appButton.addEventListener("click", function (event) {
    const os = getOS();
    let storeLink = null;
    // display the OS (remove this)
    //alert(os);
    // set store link based on the OS
    if (os === "macos" || os === "ios") {
      storeLink = "https://apps.apple.com/us/app/sweat-wallet/id1619316571";
    } else {
      storeLink =
        "https://play.google.com/store/apps/details?id=com.sweatwallet";
    }
    // redirect the user
    //window.location.href = storeLink;  //<---------------------- UNCOMMENT THIS
    // if you would like to open the link in a new
    // tab please use this line instead of the above
    window.open(storeLink, "_blank");
  });

  // helper function to get the operating system based on the user agent
  // source: https://stackoverflow.com/a/70519514
  function getOS() {
    let userAgent = window.navigator.userAgent.toLowerCase(),
      macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i,
      windowsPlatforms = /(win32|win64|windows|wince)/i,
      iosPlatforms = /(iphone|ipad|ipod)/i,
      os = null;
    if (macosPlatforms.test(userAgent)) {
      os = "macos";
    } else if (iosPlatforms.test(userAgent)) {
      os = "ios";
    } else if (windowsPlatforms.test(userAgent)) {
      os = "windows";
    } else if (/android/.test(userAgent)) {
      os = "android";
    } else if (!os && /linux/.test(userAgent)) {
      os = "linux";
    }
    return os;
  }
});
